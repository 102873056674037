define("discourse/plugins/discourse-locations/discourse/connectors/user-custom-preferences/map-location", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/discourse-locations/discourse/components/location-selector", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dIcon, _i18n, _locationSelector, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserCustomPrefsMapLocation extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked], function () {
      return null;
    }))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    searchError(error) {
      this.error = error;
    }
    static #_3 = (() => dt7948.n(this.prototype, "searchError", [_object.action]))();
    updateLocation(location) {
      this.error = null;
      this.args.outletArgs.model.custom_fields.geo_location = location;
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateLocation", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.siteSettings.location_users_map}}
          <div class="user-location-selector">
            <div class="control-group">
              <label class="control-label">{{i18n
                  "user.map_location.title"
                }}</label>
              <div class="controls location-selector-container">
                <LocationSelector
                  @location={{@outletArgs.model.custom_fields.geo_location}}
                  @onChangeCallback={{this.updateLocation}}
                  class="input-xxlarge location-selector"
                  @searchError={{this.searchError}}
                  @context={{this.context}}
                />
              </div>
              <div class="user-location-warning">
                {{#if this.error}}
                  {{this.error}}
                {{else}}
                  {{icon "circle-exclamation"}}
                  {{i18n "user.map_location.warning"}}
                {{/if}}
              </div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "p8GXIyot",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"location_users_map\"]],[[[1,\"      \"],[10,0],[14,0,\"user-location-selector\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[32,0],[\"user.map_location.title\"],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"controls location-selector-container\"],[12],[1,\"\\n            \"],[8,[32,1],[[24,0,\"input-xxlarge location-selector\"]],[[\"@location\",\"@onChangeCallback\",\"@searchError\",\"@context\"],[[30,1,[\"model\",\"custom_fields\",\"geo_location\"]],[30,0,[\"updateLocation\"]],[30,0,[\"searchError\"]],[30,0,[\"context\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"user-location-warning\"],[12],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"              \"],[1,[30,0,[\"error\"]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[32,2],[\"circle-exclamation\"],null]],[1,\"\\n              \"],[1,[28,[32,0],[\"user.map_location.warning\"],null]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/connectors/user-custom-preferences/map-location.js",
      "scope": () => [_i18n.default, _locationSelector.default, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserCustomPrefsMapLocation;
});