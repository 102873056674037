define("discourse/plugins/discourse-locations/discourse/templates/connectors/topic-title/location-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.model.editingTopic}}
    {{#if this.model.location}}
      {{#unless this.model.location.hide_marker}}
        {{location-label-container topic=this.model location=this.model.location}}
      {{/unless}}
    {{/if}}
  {{/unless}}
  */
  {
    "id": "7z+2MXXz",
    "block": "[[[41,[51,[30,0,[\"model\",\"editingTopic\"]]],[[[41,[30,0,[\"model\",\"location\"]],[[[41,[51,[30,0,[\"model\",\"location\",\"hide_marker\"]]],[[[1,\"      \"],[1,[28,[35,2],null,[[\"topic\",\"location\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"location\"]]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[],false,[\"unless\",\"if\",\"location-label-container\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/connectors/topic-title/location-details.hbs",
    "isStrictMode": false
  });
});