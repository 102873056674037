define("discourse/plugins/discourse-locations/discourse/connectors/edit-topic/edit-location-details", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/discourse-locations/discourse/components/add-location-controls", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _addLocationControls, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EditLocationDetails extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "location", [_tracking.tracked], function () {
      return (0, _object.get)(this.args.outletArgs.buffered, "location");
    }))();
    #location = (() => (dt7948.i(this, "location"), void 0))();
    updateLocation(location) {
      (0, _object.set)(this.args.outletArgs.buffered, "location", location);
      this.location = location;
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateLocation", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @outletArgs.model.showLocationControls}}
          <AddLocationControls
            @location={{this.location}}
            @category={{@outletArgs.buffered.category}}
            @updateLocation={{this.updateLocation}}
          />
        {{/if}}
      
    */
    {
      "id": "3OX8wmVe",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"model\",\"showLocationControls\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@location\",\"@category\",\"@updateLocation\"],[[30,0,[\"location\"]],[30,1,[\"buffered\",\"category\"]],[30,0,[\"updateLocation\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/connectors/edit-topic/edit-location-details.js",
      "scope": () => [_addLocationControls.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EditLocationDetails;
});