define("discourse/plugins/discourse-locations/discourse/initializers/location-map-edits", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "I18n"], function (_exports, _pluginApi, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "locations-plugin";
  var _default = _exports.default = {
    name: "location-map-renderer",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        const siteSettings = container.lookup("site-settings:main");
        if (siteSettings.location_sidebar_menu_map_link) {
          api.addCommunitySectionLink({
            name: "map",
            route: "discovery.map",
            title: _I18n.default.t("filters.map.title"),
            text: _I18n.default.t("filters.map.label")
          });
        }
        if (siteSettings.location_users_map) {
          api.addCommunitySectionLink({
            name: "users map",
            route: "locations.users-map",
            title: _I18n.default.t("directory.map.title"),
            text: _I18n.default.t("directory.map.title")
          });
        }
        api.modifyClass("component:user-card-contents", dt7948.p({
          pluginId: PLUGIN_ID,
          hasLocaleOrWebsite(user) {
            return user.geo_location || user.location || user.website_name || this.userTimezone;
          }
        }, [["method", "hasLocaleOrWebsite", [(0, _decorators.default)("user")]]]));
      });
    }
  };
});