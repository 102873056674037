define("discourse/plugins/discourse-locations/discourse/components/user-location", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse/plugins/discourse-locations/discourse/components/locations-map", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _willDestroy, _service, _dButton, _dIcon, _locationUtilities, _locationsMap, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocationMapComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "showMap", [_tracking.tracked], function () {
      return false;
    }))();
    #showMap = (() => (dt7948.i(this, "showMap"), void 0))();
    outsideClick = e => {
      if (!this.isDestroying && !(e.target.closest(".map-expand") || e.target.closest(".map-attribution") || e.target.closest(".user-location-widget") || e.target.closest("#locations-map"))) {
        this.showMap = false;
      }
    };
    get mapButtonLabel() {
      return `location.geo.${this.showMap ? "hide" : "show"}_map`;
    }
    get showMapButtonLabel() {
      return this.args.formFactor !== "card" && !this.site.mobileView;
    }
    get userLocation() {
      let locationText = "";
      if (this.args.user && this.args.user.geo_location) {
        let format = this.siteSettings.location_user_profile_format.split("|");
        let opts = {};
        if (format.length && format[0]) {
          opts["geoAttrs"] = format;
          locationText = (0, _locationUtilities.geoLocationFormat)(this.args.user.geo_location, this.site.country_codes, opts);
        } else {
          locationText = this.args.user.geo_location.address;
        }
      }
      return locationText;
    }
    get canShowMap() {
      return !document.querySelector(".leaflet-container");
    }
    bindClick() {
      document.addEventListener("click", this.outsideClick);
    }
    static #_4 = (() => dt7948.n(this.prototype, "bindClick", [_object.action]))();
    unbindClick() {
      document.removeEventListener("click", this.outsideClick);
    }
    static #_5 = (() => dt7948.n(this.prototype, "unbindClick", [_object.action]))();
    toggleMap() {
      this.showMap = !this.showMap;
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleMap", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          {{didInsert this.bindClick}}
          {{willDestroy this.unbindClick}}
          class="user-location-widget"
        >
          {{icon "location-dot"}}
          <div class="location-label">
            {{this.userLocation}}
          </div>
          {{#if this.canShowMap}}
            <div class="map-wrapper">
              <DButton
                class="widget-button btn btn-default btn-show-map btn-small btn-icon-text"
                @action={{this.toggleMap}}
                @icon="far-map"
                @label={{if this.showMapButtonLabel this.mapButtonLabel}}
              />
              {{#if this.showMap}}
                <div class="map-container small">
                  <LocationsMap @mapType="user" @user={{@user}} />
                </div>
              {{/if}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "K+2axuH2",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"user-location-widget\"],[4,[32,0],[[30,0,[\"bindClick\"]]],null],[4,[32,1],[[30,0,[\"unbindClick\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,2],[\"location-dot\"],null]],[1,\"\\n      \"],[10,0],[14,0,\"location-label\"],[12],[1,\"\\n        \"],[1,[30,0,[\"userLocation\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"canShowMap\"]],[[[1,\"        \"],[10,0],[14,0,\"map-wrapper\"],[12],[1,\"\\n          \"],[8,[32,3],[[24,0,\"widget-button btn btn-default btn-show-map btn-small btn-icon-text\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"toggleMap\"]],\"far-map\",[52,[30,0,[\"showMapButtonLabel\"]],[30,0,[\"mapButtonLabel\"]]]]],null],[1,\"\\n\"],[41,[30,0,[\"showMap\"]],[[[1,\"            \"],[10,0],[14,0,\"map-container small\"],[12],[1,\"\\n              \"],[8,[32,4],null,[[\"@mapType\",\"@user\"],[\"user\",[30,1]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/user-location.js",
      "scope": () => [_didInsert.default, _willDestroy.default, _dIcon.default, _dButton.default, _locationsMap.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LocationMapComponent;
});